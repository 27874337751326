import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { APIURL } from '@/components/Utility/APIBase'
import { useRoute } from 'vue-router'
import BaseFunctions from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { Competitions } from '@/services/CompetitionsDataService'
import MembersDataService from '@/services/MembersDataService'
import ForeignPlayersDataService from '@/services/ForeignPlayersDataService'
import RegistrationFeeDataService from '@/services/RegistrationFeeDataService'
import SysCompetition, { competitionCategory2FriendlyType, SysCompetitionMembers, competitionCategoryFriendlyType, SysCompetitionRegistration, SysCompetitionProductIds, SysCompetitionRegistrationId, SysCompetitionRows, SysCompetitionRankingsPoint, SysCompetitionRowsId, SysCompetitionResults, SysOldCompetitionResults } from '@/types/SysCompetition'
import SysMember from '@/types/SysMember'
import SysForeignPlayer from '@/types/SysForeignPlayer'
import SysCart from '@/types/SysCart'
import SysRegistrationFee from '@/types/SysRegistrationFee'
import UsersDataService from '@/services/UsersDataService'
import { DartConnect } from '@/services/DartConnectService'
// import { VuePdf } from 'vue3-pdfjs'

type competitionDataType = { competitionId: number; competitionStatus: boolean; competitionName: string; competitionRegion: string; competitionCategory: competitionCategoryFriendlyType; competitionLastEntryDate: string; competitionStart: string; competitionEnd: string; competitionPlace: string; competitionRows: SysCompetitionRowsId[]; competitionProducts: SysCompetitionProductIds[]; competitionInvitation: any[]; clubName: string; clubEmail: string; clubTelephone: string; clubSlug: string; competitionStaevneleder: string; competitionOverdommer: string; competitionStaevneLink: string }
type dataReturnType = { APIURL: string; error: any; signUps: any; competitionData: competitionDataType, results: any; oldResults: any; filterResults: any; filterRegistrations: any; members: any; foreignPlayers: any; }

/* @Options({
  components: {
    VuePdf
  }
}) */
export default class theCompetition extends Vue {
  private competitionSlug = 0
  error: any = null
  private members: SysMember[] = []
  private foreignPlayers: SysForeignPlayer[] = []
  private signUps: SysCompetitionRegistration[] = []
  private tempSignedUpMembers: SysMember[] = []
  private tempSignedUpForeignPlayers: SysForeignPlayer[] = []
  private filterRegistrations: SysCompetitionRegistration[] = []
  private results: SysCompetitionResults[] = []
  private oldResults: SysOldCompetitionResults[] = []
  private filterResults: SysCompetitionResults[] = []
  private filterOldResults: SysOldCompetitionResults[] = []
  private tempCompetition = {} as SysCompetition
  private competitionResults: SysCompetitionResults[] = []
  private allResults = false
  private rowRegistrationDisplayModal = false
  private playerRegistrationModal = false
  private resultsModal = false
  private resultsArraySize = 0
  private rowName = ''
  private rowId = 0
  private seriesName = ''
  private lastCreatedCompetitionRegistrationId = 0
  private seriesId = ''
  private player1Value: any = {}
  private player2Value: any = {}
  private player3Value: any = {}
  private player4Value: any = {}
  private playerOptions: any[] = []
  private tempMemberOptions: SysMember[] = []
  private tempForeignPlayersOptions: SysForeignPlayer[] = []
  // private playerOptions: { name: string; id: string; gender: string; foreignPlayer: boolean; birthday: string; index: number }[] = [{ name: 'Ingen spiller valgt', id: '0', gender: '', foreignPlayer: false, birthday: '', index: 0 }]
  private playerOptionIndex = 0
  private feedBackMessage = ''
  private feedBackModal = false
  private rankingInformation: { id: number; total: number; }[] = []
  private danishOrForeignPlayerValue = 1
  private successRegistration = false
  private playerDataRetrievedAndReady = false
  private languageValue = 1
  private competitionRegistrationFeeProductId = 315
  private competitionRegistrationFee = 25
  private competitionRegistrationFeeBeginDate = new Date('2024-01-01')
  private tempRegistrationFeesPaid: SysRegistrationFee[] = []
  nowDate = Date.now()
  toDaysDate = ''
  lastYearsDate = ''
  competitionData: competitionDataType = { competitionId: 0, competitionStatus: true, competitionName: '', competitionRegion: '', competitionCategory: 0, competitionLastEntryDate: '', competitionStart: '', competitionEnd: '', competitionPlace: '', competitionRows: [], competitionInvitation: [], competitionProducts: [], clubName: '', clubEmail: '', clubTelephone: '', clubSlug: '', competitionStaevneleder: '', competitionOverdommer: '', competitionStaevneLink: '' }
  usersData: any = null
  dcTournamentPlayers: any[] = []
  filteredTournametRegisteredPlayers: any[] = []
  playearSearchInputValue: any = null
  dcEventRegistrationModal = false
  dcTournamentEvents = {}
  $Message: any

  readonly name : string = 'competitioninfo'
  data (): dataReturnType {
    return {
      APIURL: APIURL,
      error: this.error,
      competitionData: this.competitionData,
      results: this.results,
      oldResults: this.oldResults,
      filterResults: this.filterResults,
      filterRegistrations: this.filterRegistrations,
      signUps: this.signUps,
      members: this.members,
      foreignPlayers: this.foreignPlayers
    }
  }

  @Watch('feedBackModal')
  onFeedBackModalStatusChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.feedBackMessage = ''
    }
  }

  @Watch('playerRegistrationModal')
  onPlayerRegistrationModalStatusChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.playerOptions = []
      this.player1Value = {}
      this.player2Value = {}
      this.player3Value = {}
      this.player4Value = {}
      this.seriesName = ''
      this.seriesId = ''
      this.successRegistration = false
    }
  }

  @Watch('resultsModal')
  onResultsModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.allResults = false
    }
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    // console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClockArr (dateString: string) : string[] {
    return [CommonFunctions.toDanishDateString(dateString, 6), CommonFunctions.getHoursAndMinutes(dateString, false)]
  }

  public findPrice (rowName: string) : number {
    let retVal = 0
    const foundElement = this.competitionData.competitionProducts.find(element => element.produkt_navn.includes(rowName))

    if (foundElement !== undefined) {
      retVal = foundElement.produkt_pris
    }

    return retVal
  }

  public danishOrForeignPlayer (nationalityValue: number) : void {
    // const series = this.seriesName.toLowerCase()

    if (nationalityValue === 1) {
      this.danishOrForeignPlayerValue = 1
      this.playerOptions = this.tempMemberOptions
    }
    if (nationalityValue === 2) {
      this.danishOrForeignPlayerValue = 2
      this.playerOptions = this.tempForeignPlayersOptions
    }
  }

  public dateLastYear (dateNow: number) : string {
    const d = new Date(dateNow)
    d.setDate(d.getDate() - 365)
    // console.log('Last year: ' + d.toISOString().slice(0, 10))

    return d.toISOString().slice(0, 10)
  }

  public getTimeOfDay (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public viewAll () : void {
    this.allResults = true
    this.viewResults(this.rowId, this.rowName)
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public viewResults (rowId: number, rowName: string) : void {
    this.rowName = rowName
    this.rowId = rowId
    console.log('Resultater for Stævne id: ' + this.competitionSlug + ' med række id: ' + rowId)
    const competitionRowId = 'staevner_id.id=' + this.competitionSlug.toString() + '&staevne_raekker_id.id=' + rowId.toString()

    Competitions.CompetitionResultsDataService.getAll('', null, competitionRowId)
      .then((response) => {
        this.competitionResults = response.data
        this.competitionResults.sort((a, b) => parseInt(a.staevne_resultater_placering) - parseInt(b.staevne_resultater_placering))
        this.resultsArraySize = this.competitionResults.length

        if (this.allResults === false) {
          this.competitionResults = this.competitionResults.slice(0, 16)
        }
        console.log(this.competitionResults)

        this.resultsModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public retrieveCompetition () : void {
    const staevneId = this.getCompetitionIdFromSlug()
    this.competitionSlug = staevneId
    // console.log('[retrieveCompetition] Fetching competition data from the API.')

    this.playerDataRetrievedAndReady = false

    Competitions.CompetitionsDataService.get(staevneId.toString())
      .then((response) => {
        this.tempCompetition = response.data
        if ((this as any).tempCompetition?.staevner_tournament_id) {
          this.dcTournamentEvents = (this as any).tempCompetition.staevner_tournament_events ? JSON.parse((this as any).tempCompetition.staevner_tournament_events) : {}
          this.getDCTournametRegisteredPlayers((this as any).tempCompetition.staevner_tournament_id)
        }
        // console.log(response.data)

        this.competitionData = {
          competitionId: Number(this.tempCompetition.id),
          competitionStatus: this.tempCompetition.staevne_status,
          competitionName: this.tempCompetition.staevne_navn,
          competitionCategory: this.tempCompetition.staevne_kategori,
          competitionRegion: (this.tempCompetition.staevne_kat2 === null ? 'alle' : this.tempCompetition.staevne_kat2.toString()),
          competitionLastEntryDate: this.tempCompetition.staevne_sidstetilmelding,
          competitionStart: this.tempCompetition.staevne_start,
          competitionEnd: this.tempCompetition.staevne_slut,
          competitionPlace: this.tempCompetition.staevne_spillested,
          competitionRows: this.tempCompetition.staevne_raekker_id,
          competitionInvitation: this.tempCompetition.staevne_invitation,
          clubName: (this.tempCompetition.klubber_id !== null ? this.tempCompetition.klubber_id.klubber_klubnavn : ''),
          clubEmail: (this.tempCompetition.klubber_id !== null ? this.tempCompetition.klubber_id.klubber_email : ''),
          clubTelephone: (this.tempCompetition.klubber_id !== null ? this.tempCompetition.klubber_id.klubber_telefonnummer : ''),
          clubSlug: (this.tempCompetition.klubber_id !== null ? this.tempCompetition.klubber_id.klubber_slug : ''),
          competitionStaevneleder: this.tempCompetition.staevne_staevneleder,
          competitionOverdommer: this.tempCompetition.staevne_overdommer,
          competitionStaevneLink: this.tempCompetition.staevne_link,
          competitionProducts: (this.tempCompetition.produkts_id !== null ? this.tempCompetition.produkts_id : [])
        }

        console.log('pdf: ' + JSON.stringify(this.competitionData.competitionInvitation))
        this.competitionData.competitionRows.sort((a, b) => (a.staevne_raekker_navn > b.staevne_raekker_navn) ? 1 : -1)
        // console.log('Kategori: ' + this.competitionData.competitionCategory)
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // console.log('[retrieveCompetition] Fetching dates from a trusted source.')

        BaseFunctions.getDatetimeFromServer()
          .then((response) => {
            this.nowDate = (((response.data === undefined || response.data === null || response.status === 0 ? '' : response.data)).trim().length > 9 ? Date.parse((response.data).trim()) : this.nowDate)
            this.toDaysDate = new Date(this.nowDate).toISOString().split('T')[0]
            this.lastYearsDate = this.dateLastYear(this.nowDate)
          })
          .catch((err) => {
            this.error = err
          })
      })
      .then(() => {
        MembersDataService.getAll('', null, 'medlem_status=true&id_gte=100')
          .then((response) => {
            this.members = response.data
            // console.log('[retrieveCompetition] Loaded member players data.')
            // this.members.splice(this.members.findIndex(obj => Number(obj.id) < 100), 1)
            const checkDate = new Date(this.nowDate).toISOString().split('T')[0]

            // Do an initial filtering to exclude members with quarantine.
            this.members = this.members.filter(obj => obj.medlem_karantaene_dato === null || obj.medlem_karantaene_dato > checkDate || obj.medlem_karantaene_slutdato < checkDate)

            // Filter based upon licens date.
            this.members = this.members.filter(obj => obj.medlem_licens_slut !== null && obj.medlem_licens_slut >= checkDate)

            // Additional filtering based on the competition category.
            // No Additional filtering for Kategori1 or Kategori4 competitions.
            // Kategori2 competitions is the regional championship, so filter is based on region, and excluding the klub Gæsteklubben (Id 22).
            if (this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori2 && this.competitionData.competitionRegion === 'jfm') {
              this.members = this.members.filter(obj => obj.klubber_id !== null && obj.klubber_id.id !== 22 && (obj.klubber_id.region_id === 4 || obj.klubber_id.region_id === 9 || obj.klubber_id.region_id === 5 || obj.klubber_id.region_id === 8 || obj.klubber_id.region_id === 6 || obj.klubber_id.region_id === 7))
            }
            if (this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori2 && this.competitionData.competitionRegion === 'sm') {
              this.members = this.members.filter(obj => obj.klubber_id !== null && obj.klubber_id.id !== 22 && (obj.klubber_id.region_id === 2 || obj.klubber_id.region_id === 3 || obj.klubber_id.region_id === 10))
            }
            // Filtering for Kategori3 competitions (DM), only excluding members of the klub Gæsteklubben (Id 22) .
            if (this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori3) {
              this.members = this.members.filter(obj => obj.klubber_id !== null && obj.klubber_id.id !== 22)
            }

            // Adding a property to properly display name.
            this.members.map((obj: any) => {
              obj.displayText = obj.user_id.firstname + ' ' + obj.user_id.lastname + ' [' + (obj.medlem_licens === null ? '-' : obj.medlem_licens) + ']'
              obj.foreignPlayer = false
              return obj
            })
          })
          .catch((err) => {
            this.error = err
          })
          .finally(() => {
            // Only fetch foreign players if it is a competition where they are allowed to participate, no need for additional filtering at this point
            if (this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori1 || this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori4) {
              ForeignPlayersDataService.getAll('', null, 'ud_spiller_status=true')
                .then((response) => {
                  // console.log('[retrieveCompetition] Loaded foreign players data.')
                  this.foreignPlayers = response.data
                  this.foreignPlayers.map((obj: any) => {
                    obj.displayText = obj.ud_spiller_fornavn + ' ' + obj.ud_spiller_efternavn + ' [' + (obj.ud_spiller_land === null ? '-' : obj.ud_spiller_land) + ']'
                    obj.foreignPlayer = true
                  })
                })
                .catch((err) => {
                  this.error = err
                })
                .finally(() => {
                  this.playerDataRetrievedAndReady = true
                })
            } else {
              // console.log('[retrieveCompetition] All data loaded.')
              this.playerDataRetrievedAndReady = true
            }
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public playerRegistration (seriesId: number, seriesName: string) : void {
    this.seriesId = seriesId.toString()
    this.seriesName = seriesName
    this.danishOrForeignPlayerValue = 1 // Default value.
    this.playerRegistrationModal = true
    seriesName = seriesName.toLowerCase()
    this.tempMemberOptions = this.members
    this.tempForeignPlayersOptions = this.foreignPlayers
    const sixteenYearsAgoDate = this.sixteenYearsAgoDate()
    const eighteenYearsAgoDate = this.eighteenYearsAgoDate()
    const thirtyfiveYearsAgoDate = this.thirtyFiveYearsAgoDate()
    // console.log(this.player1Value)

    // console.log('[playerRegistration] tempMemberOptions (prefiltering) = ' + JSON.stringify(this.tempMemberOptions))

    // Do additional filtering based on the specific series choosen.
    // If the series is a youth division. Filter out players, that are over the age of 18 years.
    if (seriesName.includes('drenge') || seriesName.includes('pige') || seriesName.includes('junior')) {
      this.tempMemberOptions = this.tempMemberOptions.filter(obj => obj.medlem_foedselsdag > eighteenYearsAgoDate)
      this.tempForeignPlayersOptions = this.tempForeignPlayersOptions.filter(obj => obj.ud_foedselsdag > eighteenYearsAgoDate)
    }
    // If the series is only for ladies, exclude male players.
    if (seriesName.includes('dame') || seriesName.includes('pige')) {
      this.tempMemberOptions = this.tempMemberOptions.filter(obj => obj.koen_id.id === 1)
      this.tempForeignPlayersOptions = this.tempForeignPlayersOptions.filter(obj => obj.koen_id.id === 1)
    }
    // If the series is for handicapped, then exclude players, that is not handicapped.
    if (seriesName.includes('para')) {
      this.tempMemberOptions = this.tempMemberOptions.filter(obj => obj.medlem_paradart === true)
      this.tempForeignPlayersOptions = this.tempForeignPlayersOptions.filter(obj => obj.ud_spiller_paradart === true)
    }
    // If the series is a oldboys series, then exclude players under the age of 35 years.
    if (seriesName.includes('oldboys')) {
      this.tempMemberOptions = this.tempMemberOptions.filter(obj => obj.medlem_foedselsdag < thirtyfiveYearsAgoDate)
      // console.log('TEST BETINGELSER: ' + JSON.stringify(this.tempMemberOptions))
      this.tempForeignPlayersOptions = this.tempForeignPlayersOptions.filter(obj => obj.ud_foedselsdag < thirtyfiveYearsAgoDate)
    }

    // console.log('[playerRegistration] tempMemberOptions (postfiltering) =' + JSON.stringify(this.tempMemberOptions))

    this.playerOptions = this.tempMemberOptions
  }

  public testUnder16 (testDate: string) : boolean {
    const d = new Date(this.nowDate)
    const d18YearsAgo = d.setDate(d.getDate() - 5478)
    d.setMonth(0)
    d.setDate(1)

    if (new Date(testDate).getTime() > d18YearsAgo) {
      return true
    }

    return false
  }

  public sixteenYearsAgoDate () : string {
    const d = new Date(this.nowDate)
    d.setDate(d.getDate() - 5843)
    d.setMonth(0)
    d.setDate(1)
    console.log('This date 16 years ago: ' + d.toISOString().slice(0, 10))

    return d.toISOString().slice(0, 10)
  }

  public eighteenYearsAgoDate () : string {
    const d = new Date(this.nowDate)
    d.setDate(d.getDate() - 6574)
    d.setMonth(0)
    d.setDate(1)
    console.log('This date 18 years ago: ' + d.toISOString().slice(0, 10))

    return d.toISOString().slice(0, 10)
  }

  public thirtyFiveYearsAgoDate () : string {
    const d = new Date(this.nowDate)
    d.setDate(d.getDate() - 12783)
    d.setMonth(11)
    d.setDate(31)
    // console.log('This date 35 years ago: ' + d.toISOString().slice(0, 10))

    return d.toISOString().slice(0, 10)
  }

  public registerSelectedPlayer () : void {
    // console.log('[registerSelectedPlayer] seriesName = ' + JSON.stringify(this.seriesName))
    // console.log('[registerSelectedPlayer] player1Value = ' + JSON.stringify(this.player1Value))
    let singleCompetitionSeriesFlag = false

    // FIXME: A player should not be registered for both mix double and oldboys at the same competition as they are played at the same time.

    // Performs the checks related to the single series.
    if (!this.seriesName.toLowerCase().includes('pairs') && !this.seriesName.toLowerCase().includes('double') && !this.seriesName.toLowerCase().includes('mix') && !this.seriesName.toLowerCase().includes('hold') && !this.seriesName.toLowerCase().includes('oldboys') && !this.seriesName.toLowerCase().includes('oldgirls') && !this.seriesName.toLowerCase().includes('u35')) {
      // Check that there actually is selected a player, and returns, if that is not the case.
      if (Object.keys(this.player1Value).length === 0) {
        this.feedBackMessage = 'Fejl: Vælg en spiller'
        this.feedBackModal = true

        return
      }

      singleCompetitionSeriesFlag = true

      // Checks if the player is already registred at an other single series.
      this.filterRegistrations = this.signUps.filter(x => !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('pairs') && !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('double') && !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('hold') && !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('oldboys') && !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('oldgirls') && !x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('u35') && !((this.seriesName.toLowerCase().includes('para') && this.seriesName.toLowerCase().includes('single')) && (x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('para') && x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('single'))) && !((this.seriesName.toLowerCase().includes('herre') || this.seriesName.toLowerCase().includes('dame')) && (x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('junior') && x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('single'))))
      // console.log(this.filterRegistrations)
      if (this.checkRegistration(this.player1Value.id)) {
        this.feedBackMessage = 'Fejl: Det er ikke muligt at være tilmeldt mere end en single stævnerække'
        this.feedBackModal = true

        return
      }
      // Checks if the selected player can play in the 'C-rækken' and returns, if that is not the case.
      if (this.seriesName.toLowerCase() === 'c-rækken') {
        this.filterResults = this.results.filter(x => x.medlem_id.id === Number(this.player1Value.id) && x.staevne_ranglistepoint !== 0)
        const filterOldResults = this.oldResults.filter(x => x.Licens === Number(this.player1Value.medlem_licens) && x.Point !== 0)
        // console.log('[registerSelectedPlayer]' + JSON.stringify(filterOldResults))

        if (this.filterResults.length > 0 || filterOldResults.length > 0) {
          this.feedBackMessage = 'Fejl: Det er desværre ikke muligt at tilmelde spilleren, da vedkommende har fået point ved et tidligere stævne, eller inden for de sidste to sæsonner har spillet i Danmarksserien eller højere rangerende turneringer'
          this.feedBackModal = true

          return
        }
      }
      // Handles checks for Mens series.
      if (this.seriesName.toLowerCase().includes('herre')) {
        // Checks if the player is under 16 and qualified to enter a mens senior series, and also not a foreign player.
        if (this.testUnder16(this.player1Value.medlem_foedselsdag) === true && !this.player1Value.foreignPlayer) {
          // if (!this.determingRankings(Number(this.player1Value.id), 'drenge', 10)) {
          // [FIXED] Fixme 4 is only for testing purpose, change to 10 before live.
          this.feedBackMessage = 'Fejl: Det er deværre ikke muligt at tilmelde spilleren, da vedkommende ikke er gammel nok'
          this.feedBackModal = true

          return
          // }
        }
      }

      // Handles checks for Womens series.
      if (this.seriesName.toLowerCase().includes('dame')) {
        // Checks if the player is under 16 and qualified to enter a womens senior series, and also not a foreign player.
        if (this.testUnder16(this.player1Value.medlem_foedselsdag) === true && !this.player1Value.foreignPlayer) {
          // if (!this.determingRankings(Number(this.player1Value.id), 'pige', 8)) {
          // [FIXED] Fixme 3 is only for testing purpose, change to 8 before live.
          this.feedBackMessage = 'Fejl: Det er deværre ikke muligt at tilmelde spilleren, da vedkommende ikke er gammel nok'
          this.feedBackModal = true

          return
          // }
        }
      }

      // Handles checks for B series, does not check if the player is foreign, since foreign players must register in the A series.
      if (this.seriesName.endsWith('B') && !this.player1Value.foreignPlayer) {
        // console.log('B row checks')
        // Checks that the player does not have enough points to qualify for the mens A series.
        if (this.seriesName.toLowerCase().includes('herre') && this.determingRankings(Number(this.player1Value.id), 'herre', 80)) {
          // [FIXED]Fixme 6 is only for testing purpose, change to 80 before live.
          this.feedBackMessage = 'Fejl: Der er desværre ikke muligt at tilmelde spilleren, da vedkommende kun har opnået kvalificeringen til ' + this.seriesName.slice(0, -1) + 'A'
          this.feedBackModal = true

          return
        }
        // Checks that the player does not have enough points to qualify for the womens A series.
        if (this.seriesName.toLowerCase().includes('dame') && this.determingRankings(Number(this.player1Value.id), 'dame', 40)) {
          // [FIXED] Fixme 4 is only for testing purpose, change to 40 before live.
          this.feedBackMessage = 'Fejl: Der er desværre ikke muligt at tilmelde spilleren, da vedkommende kun har opnået kvalificeringen til ' + this.seriesName.slice(0, -1) + 'A'
          this.feedBackModal = true

          return
        }
        // Checks that the player does not have enough points qualify for the boys A series.
        if (this.seriesName.toLowerCase().includes('drenge') && this.determingRankings(Number(this.player1Value.id), 'drenge', 16)) {
          // [FIXED] Fixme 4 is only for testing purpose, change to 16 before live.
          this.feedBackMessage = 'Fejl: Der er desværre ikke muligt at tilmelde spilleren, da kun har opnået kvalificeringen til ' + this.seriesName.slice(0, -1) + 'A'
          this.feedBackModal = true

          return
        }
        // Checks that the player does not enough points to qualify for the girls A series.
        if (this.seriesName.toLowerCase().includes('pige') && this.determingRankings(Number(this.player1Value.id), 'pige', 16)) {
          // [FIXED] Fixme 3 is only for testing purpose change to 16 before live.
          this.feedBackMessage = 'Fejl: Der er desværre ikke muligt at tilmelde spilleren, da kun har opnået kvalificeringen til ' + this.seriesName.slice(0, -1) + 'A'
          this.feedBackModal = true

          return
        }
      }
    }
    // Performs checks on the double series.
    if (this.seriesName.toLowerCase().includes('pairs') || this.seriesName.toLowerCase().includes('double')) {
      // Check that one or both players have actually been selected, and returns, if that is not the case.
      if (Object.keys(this.player1Value).length === 0 || Object.keys(this.player2Value).length === 0) {
        this.feedBackMessage = 'Fejl: En eller flere spillere mangler at blive valgt'
        this.feedBackModal = true

        return
      }
      // Checks that two different players have been choosen.
      if ((this.player1Value.foreignPlayer === this.player2Value.foreignPlayer) && (this.player1Value.id === this.player2Value.id)) {
        this.feedBackMessage = 'Fejl: Vælg venligst to forskellige spillere'
        this.feedBackModal = true

        return
      }
      // // Checks if either player already is registered in a double, or pairs series.
      // this.filterRegistrations = this.signUps.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('pairs') || (x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('double') && !this.seriesName.toLowerCase().includes('mix')))
      // // console.log('Filtered Registrations: ' + JSON.stringify(this.filterRegistrations))

      // if (this.checkRegistration(this.player1Value.id) || this.checkRegistration(this.player2Value.id)) {
      //   // console.log('ForeignPlayer: ' + this.player1Value.foreignPlayer + ' ' + this.player2Value.foreignPlayer)
      //   // console.log('Player id: ' + this.player1Value.id + ' ' + this.player2Value.id)
      //   this.feedBackMessage = 'Fejl: Det er ikke muligt at foretage tilmeldelsen, da en af spillerne allerede er tilmeldt en pairs eller double række'
      //   this.feedBackModal = true

      //   return
      // }
      // Checks that the players are of different gender with mix double and junior pairs.
      // if (this.seriesName.toLowerCase().includes('mix') || this.seriesName.toLowerCase().includes('juniorpairs')) {
      if (this.seriesName.toLowerCase().includes('mix')) {
        if (this.player1Value.koen_id.id === this.player2Value.koen_id.id) {
          this.feedBackMessage = 'Fejl: Spillerne må ikke være af samme køn'
          this.feedBackModal = true

          return
        }
      }
    }
    // Performs checks in regards to teams.
    if (this.seriesName.toLowerCase() === 'hold') {
      // Checks that all players have been selected, and returns if that is not the case.
      if (Object.keys(this.player1Value).length === 0 || Object.keys(this.player2Value).length === 0 || Object.keys(this.player3Value).length === 0 || Object.keys(this.player4Value).length === 0) {
        this.feedBackMessage = 'Fejl: Mindst en spiller mangler at blive valgt'
        this.feedBackModal = true

        return
      }
      // Checks that all players on a team is different.
      if (!this.isAllDifferent(this.player1Value.id, this.player2Value.id, this.player3Value.id, this.player4Value.id)) {
        this.feedBackMessage = 'Fejl: Alle spillerne skal være forskellige'
        this.feedBackModal = true

        return
      }
      // Check if all players on a team is from the same club.
      if (this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori2 && !this.allAreEqual(this.player1Value.klubber_id.id, this.player2Value.klubber_id.id, this.player3Value.klubber_id.id, this.player4Value.klubber_id.id)) {
        this.feedBackMessage = 'Fejl: Alle spillerne skal være fra samme klub'
        this.feedBackModal = true

        return
      }
      // Check if a member of the team is already registered in a team competition.
      this.filterRegistrations = this.signUps.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('hold'))
      if (this.checkRegistration(this.player1Value.id) || this.checkRegistration(this.player2Value.id) || this.checkRegistration(this.player3Value.id) || this.checkRegistration(this.player4Value.id)) {
        // console.log('Player ids: ' + this.player1Value.id + ', ' + this.player2Value.id + ', ' + this.player3Value.id + ', ' + this.player4Value.id)
        this.feedBackMessage = 'Fejl: En spiller eller flere af spillerne er allerede i forvejen gennem et hold blevet tilmeldt en stævne'
        this.feedBackModal = true

        return
      }
    }

    // Based on the selected series, find its corresponding product.
    const competitionProduct = this.competitionData.competitionProducts.find(element => element.produkt_navn.toLocaleLowerCase().includes(this.seriesName.toLocaleLowerCase()))
    let cartItems: SysCart[] = []
    let extraItemData: any = {}
    let quantityOfFee = 1
    quantityOfFee = (this.seriesName.toLowerCase().includes('mix') && quantityOfFee < 2 ? 2 : quantityOfFee)

    if (competitionProduct === undefined) {
      this.feedBackMessage = 'Der er sket en fejl [1]'
      this.feedBackModal = true

      return
    }

    const d1 = new Date(this.competitionRegistrationFeeBeginDate)
    const d2 = new Date(this.competitionData.competitionStart)
    // console.log('[registerSelectedPlayer()] competitionProduct.id = ' + Number(competitionProduct.id).toString())

    // Check in the cart already contains a registration for a single series competition with the same product Id for the chosen player. If there is, then bail out with an error message, as only one single series is allowed per player.
    if (singleCompetitionSeriesFlag && localStorage.getItem('cart')) {
      cartItems = JSON.parse(localStorage.getItem('cart')!)

      for (const cartItem of cartItems) {
        if (cartItem.productId !== undefined && Number(cartItem.productId) >= 1 && Number(cartItem.productId) === Number(competitionProduct.id) && cartItem.productName !== undefined && cartItem.productName !== null && !cartItem.productName.toLowerCase().includes('double') && !(cartItem.productName.toLowerCase().includes('para') && cartItem.productName.toLowerCase().includes('single'))) {
          if (cartItem.playerRegistration !== undefined && Number(this.player1Value.id) >= 1) {
            if (!this.player1Value.foreignPlayer && cartItem.playerRegistration.medlem_id !== undefined && cartItem.playerRegistration.medlem_id.length > 0 && Number(cartItem.playerRegistration.medlem_id[0].id) === Number(this.player1Value.id)) {
              this.feedBackMessage = 'Fejl: Det er ikke muligt at tilmeldes med mere end en single stævnerække'
              this.feedBackModal = true

              return
            } else if (this.player1Value.foreignPlayer && cartItem.playerRegistration.usp_id !== undefined && cartItem.playerRegistration.usp_id.length > 0 && Number(cartItem.playerRegistration.usp_id[0].id) === Number(this.player1Value.id)) {
              this.feedBackMessage = 'Fejl: Det er ikke muligt at tilmeldes med mere end en single stævnerække'
              this.feedBackModal = true

              return
            }
          }
        }
      }
    }

    // Handles the special case of team registrations, and female pairs and a Category 2 competition.
    if ((this.seriesName.toLowerCase().includes('damepairs') || this.seriesName.toLowerCase().includes('juniorpairs') || this.seriesName.toLowerCase().includes('hold')) && this.competitionData.competitionCategory === competitionCategoryFriendlyType.Kategori2) {
      quantityOfFee = 0 // Skip fee here as the function that is called will add the nessesary fee(s).
      this.specialRegistrations()
    } else {
      const memberId: { id: number }[] = []
      const foreignId: { id: number }[] = []
      let productDescriptionString = ''
      let indexOfFeeProductId = -1

      if (this.seriesName.toLowerCase().includes('pairs') || this.seriesName.toLowerCase().includes('double')) {
        productDescriptionString = 'Tilmelding af ' + this.player1Value.displayText + ', ' + this.player2Value.displayText + ' til ' + competitionProduct.produkt_navn
        quantityOfFee = 2

        if (!this.player1Value.foreignPlayer) {
          memberId.push({ id: Number(this.player1Value.id) })
        } else {
          foreignId.push({ id: Number(this.player1Value.id) })
        }

        if (!this.player2Value.foreignPlayer) {
          memberId.push({ id: Number(this.player2Value.id) })
        } else {
          foreignId.push({ id: Number(this.player2Value.id) })
        }
      } else if (this.seriesName.toLowerCase().includes('hold')) {
        productDescriptionString = 'Tilmelding af ' + this.player1Value.displayText + ', ' + this.player2Value.displayText + ', ' + this.player3Value.displayText + ', ' + this.player4Value.displayText + ' til ' + competitionProduct.produkt_navn
        quantityOfFee = 4

        if (!this.player1Value.foreignPlayer) {
          memberId.push({ id: Number(this.player1Value.id) })
        } else {
          foreignId.push({ id: Number(this.player1Value.id) })
        }

        if (!this.player2Value.foreignPlayer) {
          memberId.push({ id: Number(this.player2Value.id) })
        } else {
          foreignId.push({ id: Number(this.player2Value.id) })
        }

        if (!this.player3Value.foreignPlayer) {
          memberId.push({ id: Number(this.player3Value.id) })
        } else {
          foreignId.push({ id: Number(this.player3Value.id) })
        }

        if (!this.player4Value.foreignPlayer) {
          memberId.push({ id: Number(this.player4Value.id) })
        } else {
          foreignId.push({ id: Number(this.player4Value.id) })
        }
      } else {
        productDescriptionString = 'Tilmelding af ' + this.player1Value.displayText + ' til ' + competitionProduct.produkt_navn

        if (!this.player1Value.foreignPlayer) {
          memberId.push({ id: Number(this.player1Value.id) })
        } else {
          foreignId.push({ id: Number(this.player1Value.id) })
        }
      }

      // console.log('memberId: ' + JSON.stringify(memberId))
      // console.log('foreignId: ' + JSON.stringify(foreignId))

      const createCompetitionRegistrationData = {
        staevne_tilmelding_status: true,
        medlem_id: memberId,
        usp_id: foreignId,
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: this.seriesId
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: memberId,
        uspId: foreignId,
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(this.seriesId),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      // Constructing the cart data.
      if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', JSON.stringify([]))
      }

      cartItems = JSON.parse(localStorage.getItem('cart')!)

      // Assigning the new registration to the cart.
      const newItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: competitionProduct.produkt_pris,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: createCompetitionRegistrationData,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart

      cartItems.push(newItem)

      console.log('[registerSelectedPlayer()] d1 / d2 = ' + d1.getTime().toString() + ' / ' + d2.getTime().toString())

      if (d1.getTime() < d2.getTime()) {
        indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

        if (indexOfFeeProductId < 0) {
          extraItemData = {
            type: 6,
            clubId: 0,
            clubSaldoReduction: 0,
            competitionId: this.competitionData.competitionId,
            memberId: null,
            uspId: null
          }

          const newFeeItem = {
            placeInCart: Number(cartItems.length),
            productName: this.competitionData.competitionName,
            productDescription: 'Gebyr for stævnetilmelding',
            price: this.competitionRegistrationFee,
            dateInCart: this.toDaysDate,
            expirationDate: this.competitionData.competitionLastEntryDate,
            productId: Number(this.competitionRegistrationFeeProductId),
            quantity: quantityOfFee,
            playerRegistration: null,
            licenseRenewal: null,
            clubLicenseRenewal: null,
            eventSignUp: null,
            teamRegistration: null,
            competitionFeeData: { competitionId: this.competitionData.competitionId, playerMemberIds: [], foreignPlayerIds: [] },
            orderExtraData: extraItemData
          } as SysCart

          cartItems.push(newFeeItem)
        }
      }

      const allUniquePlayersInCartItems = this.findAllUniquePlayersInCartItems(cartItems, this.competitionData.competitionId)

      // quantityOfFee = uniquePlayerIds.length + uniqueForeignPlayerIds.length
      quantityOfFee = allUniquePlayersInCartItems.playerIds.length + allUniquePlayersInCartItems.foreignPlayerIds.length
      indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

      if (indexOfFeeProductId >= 0) {
        cartItems[indexOfFeeProductId].quantity = quantityOfFee
        cartItems[indexOfFeeProductId].competitionFeeData.playerMemberIds = allUniquePlayersInCartItems.playerIds
        cartItems[indexOfFeeProductId].competitionFeeData.foreignPlayerIds = allUniquePlayersInCartItems.foreignPlayerIds

        if (cartItems[indexOfFeeProductId].orderExtraData !== undefined && cartItems[indexOfFeeProductId].orderExtraData !== null && cartItems[indexOfFeeProductId].orderExtraData.type !== undefined && cartItems[indexOfFeeProductId].orderExtraData.type !== null && cartItems[indexOfFeeProductId].orderExtraData.type === 6 && cartItems[indexOfFeeProductId].orderExtraData.memberId !== undefined && cartItems[indexOfFeeProductId].orderExtraData.uspId !== undefined) {
          cartItems[indexOfFeeProductId].orderExtraData.memberId = allUniquePlayersInCartItems.playerIds
          cartItems[indexOfFeeProductId].orderExtraData.uspId = allUniquePlayersInCartItems.foreignPlayerIds
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      // console.log(localStorage.getItem('cart'))

      this.successRegistration = true
      this.feedBackMessage = this.languageValue === 1 ? 'Tilmeldingen er blevet lagt i kurven' : 'The registration is added to the cart'
      this.playerRegistrationModal = false
      this.feedBackModal = true

      // Competitions.CompetitionMemberRegistrationDataService.create(createCompetitionRegistrationData)
      //   .then((response) => {
      //     this.lastCreatedCompetitionRegistrationId = response.data.id
      //     console.log(response.data)
      //     this.successRegistration = true
      //     this.feedBackMessage = (this.seriesName.toLowerCase() === ('hold' || 'double' || 'pairs') ? 'Spillerne' : 'Spilleren') + ' er er successfuldt blevet tilmeldt til ' + this.competitionData.competitionName + ', ' + this.seriesName
      //     this.playerRegistrationModal = false
      //     this.retieveCompetitionRegistration()
      //   })
      //   .catch((err) => {
      //     this.error = err
      //     console.log(err)
      //   })
      // this.feedBackModal = true
    }
  }

  public specialRegistrations () {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }
    let cartItems: SysCart[] = []
    cartItems = JSON.parse(localStorage.getItem('cart')!)
    let quantityOfFee = 1
    let indexOfFeeProductId = -1
    let extraItemData: any = {}
    // console.log('[specialRegistrations()] ' + JSON.stringify(this.competitionData))

    if (this.seriesName.toLowerCase().includes('hold')) {
      // First register the team, and push to the cart.
      const combinedMemberIds: { id: number, name: string }[] = [{ id: Number(this.player1Value.id), name: this.player1Value.displayText }, { id: Number(this.player2Value.id), name: this.player2Value.displayText }, { id: Number(this.player3Value.id), name: this.player3Value.displayText }, { id: Number(this.player4Value.id), name: this.player4Value.displayText }]
      let competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('hold'))
      let seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('hold'))

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [2]'
        this.feedBackModal = true

        return
      }

      let productDescriptionString = 'Tilmelding af ' + this.player1Value.displayText + ', ' + this.player2Value.displayText + ', ' + this.player3Value.displayText + ', ' + this.player4Value.displayText + ' til ' + competitionProduct.produkt_navn
      const teamIds: { id: number }[] = []
      quantityOfFee = 4

      for (const player of combinedMemberIds) {
        teamIds.push({ id: player.id })
      }

      const createTeamRegistrationData = {
        staevne_tilmelding_status: true,
        medlem_id: teamIds,
        usp_id: [],
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: Number(seriesInfo.id)
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: teamIds,
        uspId: [],
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(seriesInfo.id),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      const newTeamItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: competitionProduct.produkt_pris,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: createTeamRegistrationData,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart

      cartItems.push(newTeamItem)

      // Second register the double and push to the cart.
      if (this.seriesName.toLocaleLowerCase().includes('aaben') || this.seriesName.toLocaleLowerCase().includes('åben')) {
        competitionProduct = this.competitionData.competitionProducts.find(el => ((el.produkt_navn.toLowerCase().includes('aaben') || el.produkt_navn.toLocaleLowerCase().includes('åben')) && el.produkt_navn.toLowerCase().includes('double')))
        seriesInfo = this.competitionData.competitionRows.find(el => ((el.staevne_raekker_navn.toLowerCase().includes('aaben') || el.staevne_raekker_navn.toLocaleLowerCase().includes('åben')) && el.staevne_raekker_navn.toLowerCase().includes('double')))
      } else {
        competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('herredouble'))
        seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('herredouble'))
      }

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [3]'
        this.feedBackModal = true

        return
      }

      const d1 = new Date(this.competitionRegistrationFeeBeginDate)
      const d2 = new Date(this.competitionData.competitionStart)

      for (let i = 1; i <= 2; i++) {
        const memberId: { id: number }[] = []
        if (i % 2 === 1) {
          productDescriptionString = 'Tilmelding af ' + combinedMemberIds[0].name + ', ' + combinedMemberIds[2].name + ' til ' + competitionProduct.produkt_navn
          memberId.push({ id: combinedMemberIds[0].id }, { id: combinedMemberIds[2].id })
        } else {
          productDescriptionString = 'Tilmelding af ' + combinedMemberIds[1].name + ', ' + combinedMemberIds[3].name + ' til ' + competitionProduct.produkt_navn
          memberId.push({ id: combinedMemberIds[1].id }, { id: combinedMemberIds[3].id })
        }

        const doubleRegistrationData = {
          staevne_tilmelding_status: true,
          medlem_id: memberId,
          usp_id: [],
          staevner_id: [{ id: this.competitionData.competitionId }],
          staevne_raekker_id: Number(seriesInfo.id)
        }

        extraItemData = {
          type: 5,
          clubId: 0,
          clubSaldoReduction: 0,
          staevnetilmeldingstatus: true,
          memberId: memberId,
          uspId: [],
          staevnerId: [{ id: this.competitionData.competitionId }],
          staevneraekkerId: Number(seriesInfo.id),
          staevnetilmeldingnavn: null,
          staevnetilmeldingland: null
        }

        const newDoubleItem = {
          placeInCart: Number(cartItems.length),
          productName: competitionProduct.produkt_navn,
          productDescription: productDescriptionString,
          price: 0,
          dateInCart: this.toDaysDate,
          expirationDate: this.competitionData.competitionLastEntryDate,
          productId: Number(competitionProduct.id),
          quantity: 1,
          playerRegistration: doubleRegistrationData,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          orderExtraData: extraItemData
        } as SysCart

        cartItems.push(newDoubleItem)
      }

      // Third Register the team member in the individual matches.
      if (this.seriesName.toLocaleLowerCase().includes('aaben') || this.seriesName.toLocaleLowerCase().includes('åben')) {
        competitionProduct = this.competitionData.competitionProducts.find(el => ((el.produkt_navn.toLowerCase().includes('aaben') || el.produkt_navn.toLocaleLowerCase().includes('åben')) && el.produkt_navn.toLowerCase().includes('single')))
        seriesInfo = this.competitionData.competitionRows.find(el => ((el.staevne_raekker_navn.toLowerCase().includes('aaben') || el.staevne_raekker_navn.toLocaleLowerCase().includes('åben')) && el.staevne_raekker_navn.toLowerCase().includes('single')))
      } else {
        competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('herresingle'))
        seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('herresingle'))
      }

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [4]'
        this.feedBackModal = true

        return
      }

      for (const player of combinedMemberIds) {
        // const memberId: { id: number }[] = []
        productDescriptionString = 'Tilmelding af ' + player.name + ' til ' + competitionProduct.produkt_navn
        // memberId.push({ id: player.id })

        const playerRegistrationData = {
          staevne_tilmelding_status: true,
          medlem_id: [{ id: player.id }],
          usp_id: [],
          staevner_id: [{ id: this.competitionData.competitionId }],
          staevne_raekker_id: Number(seriesInfo.id)
        }

        extraItemData = {
          type: 5,
          clubId: 0,
          clubSaldoReduction: 0,
          staevnetilmeldingstatus: true,
          memberId: [{ id: player.id }],
          uspId: [],
          staevnerId: [{ id: this.competitionData.competitionId }],
          staevneraekkerId: Number(seriesInfo.id),
          staevnetilmeldingnavn: null,
          staevnetilmeldingland: null
        }

        const newSingleItem = {
          placeInCart: Number(cartItems.length),
          productName: competitionProduct.produkt_navn,
          productDescription: productDescriptionString,
          price: 0,
          dateInCart: this.toDaysDate,
          expirationDate: this.competitionData.competitionLastEntryDate,
          productId: Number(competitionProduct.id),
          quantity: 1,
          playerRegistration: playerRegistrationData,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          orderExtraData: extraItemData
        } as SysCart

        cartItems.push(newSingleItem)
      }

      if (d1.getTime() < d2.getTime()) {
        indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

        if (indexOfFeeProductId < 0) {
          extraItemData = {
            type: 6,
            clubId: 0,
            clubSaldoReduction: 0,
            competitionId: this.competitionData.competitionId,
            memberId: null,
            uspId: null
          }

          const newFeeItem = {
            placeInCart: Number(cartItems.length),
            productName: this.competitionData.competitionName,
            productDescription: 'Gebyr for stævnetilmelding',
            price: this.competitionRegistrationFee,
            dateInCart: this.toDaysDate,
            expirationDate: this.competitionData.competitionLastEntryDate,
            productId: Number(this.competitionRegistrationFeeProductId),
            quantity: quantityOfFee,
            playerRegistration: null,
            licenseRenewal: null,
            clubLicenseRenewal: null,
            eventSignUp: null,
            teamRegistration: null,
            competitionFeeData: { competitionId: this.competitionData.competitionId, playerMemberIds: [], foreignPlayerIds: [] },
            orderExtraData: extraItemData
          } as SysCart

          cartItems.push(newFeeItem)
        }
      }

      const allUniquePlayersInCartItems = this.findAllUniquePlayersInCartItems(cartItems, this.competitionData.competitionId)

      quantityOfFee = allUniquePlayersInCartItems.playerIds.length + allUniquePlayersInCartItems.foreignPlayerIds.length
      indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

      if (indexOfFeeProductId >= 0) {
        cartItems[indexOfFeeProductId].quantity = quantityOfFee
        cartItems[indexOfFeeProductId].competitionFeeData.playerMemberIds = allUniquePlayersInCartItems.playerIds
        cartItems[indexOfFeeProductId].competitionFeeData.foreignPlayerIds = allUniquePlayersInCartItems.foreignPlayerIds

        if (cartItems[indexOfFeeProductId].orderExtraData !== undefined && cartItems[indexOfFeeProductId].orderExtraData !== null && cartItems[indexOfFeeProductId].orderExtraData.type !== undefined && cartItems[indexOfFeeProductId].orderExtraData.type !== null && cartItems[indexOfFeeProductId].orderExtraData.type === 6 && cartItems[indexOfFeeProductId].orderExtraData.memberId !== undefined && cartItems[indexOfFeeProductId].orderExtraData.uspId !== undefined) {
          cartItems[indexOfFeeProductId].orderExtraData.memberId = allUniquePlayersInCartItems.playerIds
          cartItems[indexOfFeeProductId].orderExtraData.uspId = allUniquePlayersInCartItems.foreignPlayerIds
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      // console.log(localStorage.getItem('cart'))

      this.successRegistration = true
      this.feedBackMessage = this.languageValue === 1 ? 'Tilmeldingen er blevet lagt i kurven' : 'The registration is added to the cart'
      this.playerRegistrationModal = false
      this.feedBackModal = true
    } else if (this.seriesName.toLowerCase().includes('juniorpairs')) {
      // Handles registration with "Juniorpairs".
      const combinedMemberIds: { id: number, name: string }[] = [{ id: Number(this.player1Value.id), name: this.player1Value.displayText }, { id: Number(this.player2Value.id), name: this.player2Value.displayText }]
      let genderPairType = 0 // 0 = mix male & female, 1 = females only, 2 = males only

      // Adjust indication variable for which genderwise type of pairing the registration is about.
      if (this.player1Value.koen_id.id === this.player2Value.koen_id.id) {
        genderPairType = this.player1Value.koen_id.id >= 2 ? 2 : 1
      }

      // First make a pair registration.
      // console.log('[specialRegistrations()] combinedMemberIds = ' + JSON.stringify(combinedMemberIds))

      let competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('juniorpairs'))
      let seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('juniorpairs'))

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [5]'
        this.feedBackModal = true

        return
      }

      let productDescriptionString = 'Tilmelding af ' + combinedMemberIds[0].name + ' og ' + combinedMemberIds[1].name + ' til ' + competitionProduct.produkt_navn
      let memberId: { id: number }[] = []
      quantityOfFee = 2

      for (const player of combinedMemberIds) {
        memberId.push({ id: player.id })
      }

      const teamRegistrationData1 = {
        staevne_tilmelding_status: true,
        medlem_id: memberId,
        usp_id: [],
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: Number(seriesInfo.id)
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: memberId,
        uspId: [],
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(seriesInfo.id),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      const newPairItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: competitionProduct.produkt_pris,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: teamRegistrationData1,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart

      cartItems.push(newPairItem)

      // if (genderPairType === 0) {
      competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('juniordouble'))
      seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('juniordouble'))
      // } else {
      //   competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes((genderPairType === 1 ? 'pigedouble' : 'drengedouble')))
      //   seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes((genderPairType === 1 ? 'pigedouble' : 'drengedouble')))
      // }

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [6]'
        this.feedBackModal = true

        return
      }

      const d1 = new Date(this.competitionRegistrationFeeBeginDate)
      const d2 = new Date(this.competitionData.competitionStart)

      // Second make a double registration.
      productDescriptionString = 'Tilmelding af ' + combinedMemberIds[0].name + ' og ' + combinedMemberIds[1].name + ' til ' + competitionProduct.produkt_navn
      memberId = []

      for (const player of combinedMemberIds) {
        memberId.push({ id: player.id })
      }

      const teamRegistrationData2 = {
        staevne_tilmelding_status: true,
        medlem_id: memberId,
        usp_id: [],
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: Number(seriesInfo.id)
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: memberId,
        uspId: [],
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(seriesInfo.id),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      const newDoubleItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: 0,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: teamRegistrationData2,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart

      cartItems.push(newDoubleItem)

      // Third make a "pigesingle" or "drengesingle" or "juniorsingle" registration for each member of the pair.
      competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('pigesingle'))
      seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('pigesingle'))
      let competitionProduct2 = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('drengesingle'))
      let seriesInfo2 = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('drengesingle'))

      if (competitionProduct === undefined || seriesInfo === undefined || competitionProduct2 === undefined || seriesInfo2 === undefined) {
        // Try with "junior" single product name and competition series name instead.
        competitionProduct = this.competitionData.competitionProducts.find(el => (el.produkt_navn.toLowerCase().includes('junior') && el.produkt_navn.toLowerCase().includes('single')))
        seriesInfo = this.competitionData.competitionRows.find(el => (el.staevne_raekker_navn.toLowerCase().includes('junior') && el.staevne_raekker_navn.toLowerCase().includes('single')))
        competitionProduct2 = this.competitionData.competitionProducts.find(el => (el.produkt_navn.toLowerCase().includes('junior') && el.produkt_navn.toLowerCase().includes('single')))
        seriesInfo2 = this.competitionData.competitionRows.find(el => (el.staevne_raekker_navn.toLowerCase().includes('junior') && el.staevne_raekker_navn.toLowerCase().includes('single')))

        if (competitionProduct === undefined || seriesInfo === undefined || competitionProduct2 === undefined || seriesInfo2 === undefined) {
          this.feedBackMessage = 'Der er sket en fejl [7]'
          this.feedBackModal = true

          return
        }
      }

      let currentCompProd = competitionProduct

      for (const player of combinedMemberIds) {
        // const memberId: { id: number }[] = []
        currentCompProd = (((player.id === Number(this.player1Value.id) && this.player1Value.koen_id.id >= 2) || (player.id === Number(this.player2Value.id) && this.player2Value.koen_id.id >= 2)) ? competitionProduct2 : competitionProduct)
        productDescriptionString = 'Tilmelding af ' + player.name + ' til ' + currentCompProd.produkt_navn
        // memberId.push({ id: player.id })

        const playerRegistration = {
          staevne_tilmelding_status: true,
          medlem_id: [{ id: player.id }],
          usp_id: [],
          staevner_id: [{ id: this.competitionData.competitionId }],
          staevne_raekker_id: (((player.id === Number(this.player1Value.id) && this.player1Value.koen_id.id >= 2) || (player.id === Number(this.player2Value.id) && this.player2Value.koen_id.id >= 2)) ? Number(seriesInfo2.id) : Number(seriesInfo.id))
        }

        extraItemData = {
          type: 5,
          clubId: 0,
          clubSaldoReduction: 0,
          staevnetilmeldingstatus: true,
          memberId: [{ id: player.id }],
          uspId: [],
          staevnerId: [{ id: this.competitionData.competitionId }],
          staevneraekkerId: (((player.id === Number(this.player1Value.id) && this.player1Value.koen_id.id >= 2) || (player.id === Number(this.player2Value.id) && this.player2Value.koen_id.id >= 2)) ? Number(seriesInfo2.id) : Number(seriesInfo.id)),
          staevnetilmeldingnavn: null,
          staevnetilmeldingland: null
        }

        const newSingleItem = {
          placeInCart: Number(cartItems.length),
          productName: currentCompProd.produkt_navn,
          productDescription: productDescriptionString,
          price: 0,
          dateInCart: this.toDaysDate,
          expirationDate: this.competitionData.competitionLastEntryDate,
          productId: Number(currentCompProd.id),
          quantity: 1,
          playerRegistration: playerRegistration,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          orderExtraData: extraItemData
        } as SysCart

        cartItems.push(newSingleItem)
      }

      if (d1.getTime() < d2.getTime()) {
        indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

        extraItemData = {
          type: 6,
          clubId: 0,
          clubSaldoReduction: 0,
          competitionId: 0,
          memberId: null,
          uspId: null
        }

        if (indexOfFeeProductId < 0) {
          const newFeeItem = {
            placeInCart: Number(cartItems.length),
            productName: this.competitionData.competitionName,
            productDescription: 'Gebyr for stævnetilmelding',
            price: this.competitionRegistrationFee,
            dateInCart: this.toDaysDate,
            expirationDate: this.competitionData.competitionLastEntryDate,
            productId: Number(this.competitionRegistrationFeeProductId),
            quantity: quantityOfFee,
            playerRegistration: null,
            licenseRenewal: null,
            clubLicenseRenewal: null,
            eventSignUp: null,
            teamRegistration: null,
            competitionFeeData: { competitionId: this.competitionData.competitionId, playerMemberIds: [], foreignPlayerIds: [] },
            orderExtraData: extraItemData
          } as SysCart

          cartItems.push(newFeeItem)
        }
      }

      const allUniquePlayersInCartItems = this.findAllUniquePlayersInCartItems(cartItems, this.competitionData.competitionId)

      quantityOfFee = allUniquePlayersInCartItems.playerIds.length + allUniquePlayersInCartItems.foreignPlayerIds.length
      indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

      if (indexOfFeeProductId >= 0) {
        cartItems[indexOfFeeProductId].quantity = quantityOfFee
        cartItems[indexOfFeeProductId].competitionFeeData.playerMemberIds = allUniquePlayersInCartItems.playerIds
        cartItems[indexOfFeeProductId].competitionFeeData.foreignPlayerIds = allUniquePlayersInCartItems.foreignPlayerIds

        if (cartItems[indexOfFeeProductId].orderExtraData !== undefined && cartItems[indexOfFeeProductId].orderExtraData !== null && cartItems[indexOfFeeProductId].orderExtraData.type !== undefined && cartItems[indexOfFeeProductId].orderExtraData.type !== null && cartItems[indexOfFeeProductId].orderExtraData.type === 6 && cartItems[indexOfFeeProductId].orderExtraData.memberId !== undefined && cartItems[indexOfFeeProductId].orderExtraData.uspId !== undefined) {
          cartItems[indexOfFeeProductId].orderExtraData.memberId = allUniquePlayersInCartItems.playerIds
          cartItems[indexOfFeeProductId].orderExtraData.uspId = allUniquePlayersInCartItems.foreignPlayerIds
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      // console.log(localStorage.getItem('cart'))

      this.successRegistration = true
      this.feedBackMessage = this.languageValue === 1 ? 'Tilmeldingen er blevet lagt i kurven' : 'The registration is added to the cart'
      this.playerRegistrationModal = false
      this.feedBackModal = true
    } else {
      // Handles registration with "Damepairs".
      const combinedMemberIds: { id: number, name: string }[] = [{ id: Number(this.player1Value.id), name: this.player1Value.displayText }, { id: Number(this.player2Value.id), name: this.player2Value.displayText }]

      // First make a pair registration.
      // console.log('[specialRegistrations()] combinedMemberIds = ' + JSON.stringify(combinedMemberIds))

      let competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('damepairs'))
      let seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('damepairs'))

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [8]'
        this.feedBackModal = true

        return
      }

      let productDescriptionString = 'Tilmelding af ' + combinedMemberIds[0].name + ' og ' + combinedMemberIds[1].name + ' til ' + competitionProduct.produkt_navn
      let memberId: { id: number }[] = []
      quantityOfFee = 2

      for (const player of combinedMemberIds) {
        memberId.push({ id: player.id })
      }

      const teamRegistrationData1 = {
        staevne_tilmelding_status: true,
        medlem_id: memberId,
        usp_id: [],
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: Number(seriesInfo.id)
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: memberId,
        uspId: [],
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(seriesInfo.id),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      const newPairItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: competitionProduct.produkt_pris,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: teamRegistrationData1,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart
      // console.log('[specialRegistrations()] seriesInfo = ' + JSON.stringify(newPairItem))

      cartItems.push(newPairItem)

      competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('damedouble'))
      seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('damedouble'))

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [9]'
        this.feedBackModal = true

        return
      }

      const d1 = new Date(this.competitionRegistrationFeeBeginDate)
      const d2 = new Date(this.competitionData.competitionStart)

      // Second make a double registration.
      productDescriptionString = 'Tilmelding af ' + combinedMemberIds[0].name + ' og ' + combinedMemberIds[1].name + ' til ' + competitionProduct.produkt_navn
      memberId = []

      for (const player of combinedMemberIds) {
        memberId.push({ id: player.id })
      }

      const teamRegistrationData2 = {
        staevne_tilmelding_status: true,
        medlem_id: memberId,
        usp_id: [],
        staevner_id: [{ id: this.competitionData.competitionId }],
        staevne_raekker_id: Number(seriesInfo.id)
      }

      extraItemData = {
        type: 5,
        clubId: 0,
        clubSaldoReduction: 0,
        staevnetilmeldingstatus: true,
        memberId: memberId,
        uspId: [],
        staevnerId: [{ id: this.competitionData.competitionId }],
        staevneraekkerId: Number(seriesInfo.id),
        staevnetilmeldingnavn: null,
        staevnetilmeldingland: null
      }

      const newDoubleItem = {
        placeInCart: Number(cartItems.length),
        productName: competitionProduct.produkt_navn,
        productDescription: productDescriptionString,
        price: 0,
        dateInCart: this.toDaysDate,
        expirationDate: this.competitionData.competitionLastEntryDate,
        productId: Number(competitionProduct.id),
        quantity: 1,
        playerRegistration: teamRegistrationData2,
        licenseRenewal: null,
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        orderExtraData: extraItemData
      } as SysCart

      cartItems.push(newDoubleItem)

      // Third make a "dame single" registration for each member of the double.
      competitionProduct = this.competitionData.competitionProducts.find(el => el.produkt_navn.toLowerCase().includes('damesingle'))
      seriesInfo = this.competitionData.competitionRows.find(el => el.staevne_raekker_navn.toLowerCase().includes('damesingle'))

      if (competitionProduct === undefined || seriesInfo === undefined) {
        this.feedBackMessage = 'Der er sket en fejl [10]'
        this.feedBackModal = true

        return
      }

      for (const player of combinedMemberIds) {
        // const memberId: { id: number }[] = []
        productDescriptionString = 'Tilmelding af ' + player.name + ' til ' + competitionProduct.produkt_navn
        // memberId.push({ id: player.id })

        const playerRegistration = {
          staevne_tilmelding_status: true,
          medlem_id: [{ id: player.id }],
          usp_id: [],
          staevner_id: [{ id: this.competitionData.competitionId }],
          staevne_raekker_id: Number(seriesInfo.id)
        }

        extraItemData = {
          type: 5,
          clubId: 0,
          clubSaldoReduction: 0,
          staevnetilmeldingstatus: true,
          memberId: [{ id: player.id }],
          uspId: [],
          staevnerId: [{ id: this.competitionData.competitionId }],
          staevneraekkerId: Number(seriesInfo.id),
          staevnetilmeldingnavn: null,
          staevnetilmeldingland: null
        }

        const newSingleItem = {
          placeInCart: Number(cartItems.length),
          productName: competitionProduct.produkt_navn,
          productDescription: productDescriptionString,
          price: 0,
          dateInCart: this.toDaysDate,
          expirationDate: this.competitionData.competitionLastEntryDate,
          productId: Number(competitionProduct.id),
          quantity: 1,
          playerRegistration: playerRegistration,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          orderExtraData: extraItemData
        } as SysCart

        cartItems.push(newSingleItem)
      }

      if (d1.getTime() < d2.getTime()) {
        indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

        if (indexOfFeeProductId < 0) {
          extraItemData = {
            type: 6,
            clubId: 0,
            clubSaldoReduction: 0,
            competitionId: 0,
            memberId: null,
            uspId: null
          }

          const newFeeItem = {
            placeInCart: Number(cartItems.length),
            productName: this.competitionData.competitionName,
            productDescription: 'Gebyr for stævnetilmelding',
            price: this.competitionRegistrationFee,
            dateInCart: this.toDaysDate,
            expirationDate: this.competitionData.competitionLastEntryDate,
            productId: Number(this.competitionRegistrationFeeProductId),
            quantity: quantityOfFee,
            playerRegistration: null,
            licenseRenewal: null,
            clubLicenseRenewal: null,
            eventSignUp: null,
            teamRegistration: null,
            competitionFeeData: { competitionId: this.competitionData.competitionId, playerMemberIds: [], foreignPlayerIds: [] },
            orderExtraData: extraItemData
          } as SysCart

          cartItems.push(newFeeItem)
        }
      }

      const allUniquePlayersInCartItems = this.findAllUniquePlayersInCartItems(cartItems, this.competitionData.competitionId)

      quantityOfFee = allUniquePlayersInCartItems.playerIds.length + allUniquePlayersInCartItems.foreignPlayerIds.length
      indexOfFeeProductId = cartItems.findIndex(obj => obj.productId === Number(this.competitionRegistrationFeeProductId) && obj.competitionFeeData.competitionId === this.competitionData.competitionId)

      if (indexOfFeeProductId >= 0) {
        cartItems[indexOfFeeProductId].quantity = quantityOfFee
        cartItems[indexOfFeeProductId].competitionFeeData.playerMemberIds = allUniquePlayersInCartItems.playerIds
        cartItems[indexOfFeeProductId].competitionFeeData.foreignPlayerIds = allUniquePlayersInCartItems.foreignPlayerIds

        if (cartItems[indexOfFeeProductId].orderExtraData !== undefined && cartItems[indexOfFeeProductId].orderExtraData !== null && cartItems[indexOfFeeProductId].orderExtraData.type !== undefined && cartItems[indexOfFeeProductId].orderExtraData.type !== null && cartItems[indexOfFeeProductId].orderExtraData.type === 6 && cartItems[indexOfFeeProductId].orderExtraData.memberId !== undefined && cartItems[indexOfFeeProductId].orderExtraData.uspId !== undefined) {
          cartItems[indexOfFeeProductId].orderExtraData.memberId = allUniquePlayersInCartItems.playerIds
          cartItems[indexOfFeeProductId].orderExtraData.uspId = allUniquePlayersInCartItems.foreignPlayerIds
        }
      }

      localStorage.setItem('cart', JSON.stringify(cartItems))
      // console.log(localStorage.getItem('cart'))

      this.successRegistration = true
      this.feedBackMessage = this.languageValue === 1 ? 'Tilmeldingen er blevet lagt i kurven' : 'The registration is added to the cart'
      this.playerRegistrationModal = false
      this.feedBackModal = true
    }
  }

  public checkRegistration (idToCheck: number) : boolean {
    const id = Number(idToCheck)

    for (const item of this.filterRegistrations) {
      if (this.danishOrForeignPlayerValue === 1) {
        if (item.medlem_id.length > 0) {
          for (const member of item.medlem_id) {
            if (member.id === id) {
              // console.log('Udtræk: ' + JSON.stringify(item))
              return true
            }
          }
        }
      } else {
        if (item.usp_id.length > 0) {
          for (const foreign of item.usp_id) {
            if (foreign.id === id) {
              // console.log('Udtræk UDS: ' + JSON.stringify(item))
              return true
            }
          }
        }
      }
    }

    return false
  }

  // Find all uniques players (and uniques foreign players) in the cart set for registration to at competion, that matches a certain competition by id.
  public findAllUniquePlayersInCartItems (cartItems: SysCart[], competitionId: number) : { playerIds: number[], foreignPlayerIds: number[] } {
    const uniquePlayerIds: number[] = []
    const uniqueForeignPlayerIds: number[] = []

    for (const cartItem of cartItems) {
      if (cartItem.playerRegistration !== null) {
        console.log('[findAllUniquePlayersInCartItems()] playerRegistration = ' + JSON.stringify(cartItem.playerRegistration))
        console.log('[findAllUniquePlayersInCartItems()] competitionId = ' + competitionId.toString())

        if (cartItem.playerRegistration.staevner_id !== undefined && cartItem.playerRegistration.staevner_id[0].id !== undefined && cartItem.playerRegistration.staevner_id[0].id !== null && cartItem.playerRegistration.staevner_id[0].id === competitionId) {
          if (cartItem.playerRegistration.medlem_id !== undefined && cartItem.playerRegistration.medlem_id !== null) {
            for (let prMemberIndex = 0; prMemberIndex < cartItem.playerRegistration.medlem_id.length; prMemberIndex++) {
              if (cartItem.playerRegistration.medlem_id[prMemberIndex].id !== undefined && cartItem.playerRegistration.medlem_id[prMemberIndex].id !== null) {
                const indexOfprMemberId = uniquePlayerIds.findIndex(el => el === Number(cartItem.playerRegistration.medlem_id[prMemberIndex].id))

                if (Number(cartItem.playerRegistration.medlem_id[prMemberIndex].id) !== 0 && indexOfprMemberId < 0) {
                  // Exclude player id, if player has already earlier paid the registration fee for the same competition.
                  if (this.tempRegistrationFeesPaid.findIndex(el => Number((el.medlem_id !== undefined && el.medlem_id !== null && el.medlem_id.id !== undefined && el.medlem_id.id !== null ? el.medlem_id.id : -1)) === Number(cartItem.playerRegistration.medlem_id[prMemberIndex].id)) < 0) {
                    uniquePlayerIds.push(Number(cartItem.playerRegistration.medlem_id[prMemberIndex].id))
                  }
                }
              }
            }
          }

          if (cartItem.playerRegistration.usp_id !== undefined && cartItem.playerRegistration.usp_id !== null) {
            for (let prMemberIndex = 0; prMemberIndex < cartItem.playerRegistration.usp_id.length; prMemberIndex++) {
              if (cartItem.playerRegistration.usp_id[prMemberIndex].id !== undefined && cartItem.playerRegistration.usp_id[prMemberIndex].id !== null) {
                const indexOfprMemberId = uniqueForeignPlayerIds.findIndex(el => el === Number(cartItem.playerRegistration.usp_id[prMemberIndex].id))

                if (Number(cartItem.playerRegistration.usp_id[prMemberIndex].id) !== 0 && indexOfprMemberId < 0) {
                  // Exclude player id, if player has already earlier paid the registration fee for the same competition.
                  if (this.tempRegistrationFeesPaid.findIndex(el => Number((el.usp_id !== undefined && el.usp_id !== null && el.usp_id.id !== undefined && el.usp_id.id !== null ? el.usp_id.id : -1)) === Number(cartItem.playerRegistration.usp_id[prMemberIndex].id)) < 0) {
                    uniqueForeignPlayerIds.push(Number(cartItem.playerRegistration.usp_id[prMemberIndex].id))
                  }
                }
              }
            }
          }
        }
      }
    }

    return { playerIds: uniquePlayerIds, foreignPlayerIds: uniqueForeignPlayerIds }
  }

  // public checkForeignRegistration () : boolean {
  //   for (const item of this.filterRegistrations) {
  //     for (const foreign of item.usp_id) {
  //       if (foreign.id === this.player1Value.id) {
  //         return true
  //       }
  //     }
  //   }
  //   return false
  // }

  // public allAreEqual (one: number, two: number, three: number, four: number) : boolean {
  //   const params = Array.prototype.slice.call(arguments)
  //   return params.every((val, i, arr) => val === arr[0])
  // }

  public allAreEqual (...args: number[]) : boolean {
    return args.every((val, i, arr) => val === arr[0])
  }

  // public isAllDifferent (one: number, two: number, three: number, four: number) : boolean {
  //   const params = Array.prototype.slice.call(arguments)
  //   console.log(params)
  //   for (let i = 0; i < params.length; i++) {
  //     if (params.lastIndexOf(params[i]) !== i) {
  //       return false
  //     }
  //   }
  //   return true
  // }

  public isAllDifferent (...args: number[]) : boolean {
    // console.log(args)

    for (let i = 0; i < args.length; i++) {
      if (args.lastIndexOf(args[i]) !== i) {
        return false
      }
    }

    return true
  }

  public calcTotal (id : number) : number {
    const total: any[] = []

    for (const item of this.filterResults) {
      if (id === item.medlem_id.id) {
        total.push(item.staevne_ranglistepoint)
      }
    }

    return total.reduce(function (total, num) { return total + num }, 0)
  }

  public calcTotalOfOld (license: number) : number {
    const total: any[] = []

    for (const item of this.filterOldResults) {
      if (license === item.Licens) {
        total.push(item.Point)
      }
    }

    return total.reduce(function (total, num) { return total + num }, 0)
  }

  // Determine the whether the player member is within a certain rank. It will return true if outside (higher total points value) the specified rank (topX).
  public determingRankings (playerId: number, division: string, topX: number) : boolean {
    let genderToInclude = 0 // Used for old points results only.
    this.rankingInformation = []

    if (division === 'drenge') {
      this.filterResults = this.results.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('drenge') || x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('junior'))
      genderToInclude = 2
      this.filterOldResults = this.oldResults.filter(x => x.Klasse.toString().toLowerCase().includes('dre'))
    }
    if (division === 'pige') {
      this.filterResults = this.results.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('pige'))
      this.filterOldResults = this.oldResults.filter(x => x.Klasse.toString().toLowerCase().includes('pig'))
      genderToInclude = 1
    }
    if (division === 'herre') {
      this.filterResults = this.results.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('herre') || (x.staevne_raekker_id.staevne_raekker_navn.toLowerCase() === 'c-rækken' && x.medlem_id.koen_id === 2))
      this.filterOldResults = this.oldResults.filter(x => x.Klasse.toString().toLowerCase().includes('her'))
      genderToInclude = 2
    }
    if (division === 'dame') {
      this.filterResults = this.results.filter(x => x.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('dame') || (x.staevne_raekker_id.staevne_raekker_navn.toLowerCase() === 'c-rækken' && x.medlem_id.koen_id === 1))
      this.filterOldResults = this.oldResults.filter(x => x.Klasse.toString().toLowerCase().includes('dam'))
      genderToInclude = 1
    }
    // console.log('Ranking array: ' + JSON.stringify(this.filterResults))
    if (topX >= this.filterResults.length) {
      return false // If there are less players in the division than the criterion set by DDU e.g., then this means that every player is automatically qualified.
    }

    // Takes all the results and calculate for each id the total number of points, adding it to the end of a seperate array for holding ranking informations.
    for (const objItem of this.filterResults) {
      this.rankingInformation.push({
        id: (objItem.medlem_id.id === undefined ? 0 : Number(objItem.medlem_id.id)),
        total: (objItem.medlem_id.id === undefined ? 0 : this.calcTotal(Number(objItem.medlem_id.id)))
      })
    }
    // console.log('Total points for each id: ' + JSON.stringify(this.rankingInformation))

    // Handle points from old results.
    let memberData: SysMember[] = []
    let tempRankingInformationOld: { id: number; total: number; }[] = []
    for (const objItem of this.filterOldResults) {
      // Find member data for the licens.
      memberData = this.members.filter(x => x.medlem_licens === objItem.Licens)

      if (memberData !== undefined && memberData !== null && memberData.length >= 1 && memberData[0].koen_id.id === genderToInclude) {
        tempRankingInformationOld.push({
          id: (memberData[0].id === undefined ? 0 : Number(memberData[0].id)),
          total: (memberData[0].id === undefined ? 0 : this.calcTotalOfOld(Number(objItem.Licens)))
        })
      }
    }

    // console.log('Total points in old results: ' + JSON.stringify(tempRankingInformationOld))

    // Then eliminate the duplicate from the the arrays.
    this.rankingInformation = this.rankingInformation.filter((value, index, self) =>
      index === self.findIndex((t) =>
        t.id === value.id
      ))
    tempRankingInformationOld = tempRankingInformationOld.filter((value, index, self) =>
      index === self.findIndex((t) =>
        t.id === value.id
      ))

    // Add total points found from old results to the point of the normal results.
    let tmpIndex = 0
    for (let arrIndex = 0; arrIndex < this.rankingInformation.length; arrIndex++) {
      tmpIndex = tempRankingInformationOld.findIndex((x) => x.id === this.rankingInformation[arrIndex].id)

      if (tmpIndex >= 0) {
        this.rankingInformation[arrIndex].total += tempRankingInformationOld[tmpIndex].total
      }
    }
    // Loop through the temporary ranking information array for the old results, and add missing elements to the normal ranking information array.
    for (let arrIndex = 0; arrIndex < tempRankingInformationOld.length; arrIndex++) {
      tmpIndex = this.rankingInformation.findIndex((x) => x.id === tempRankingInformationOld[arrIndex].id)

      if (tmpIndex < 0) {
        this.rankingInformation.push(tempRankingInformationOld[arrIndex])
      }
    }

    // console.log('Total points after duplicates have been eliminated: ' + JSON.stringify(this.rankingInformation))

    // Checks if the id being tested have actually gotten any points, and returns false, if that is not the case.
    if (!this.rankingInformation.some(data => data.id === Number(playerId))) {
      // console.log('id: ' + this.player1Value.id + ' does not have any points')

      return false
    }

    // Then sort the array from the highest number of points to the lowest number of points.
    this.rankingInformation.sort((a, b) => b.total - a.total)
    // console.log('Array after have been sorten: ' + JSON.stringify(this.rankingInformation))

    // console.log('Total points after sorting: ' + JSON.stringify(this.rankingInformation))

    // Determines the points at index = topX, and checks if the id have more, or equal that amount.
    const pointsAtX = this.rankingInformation[topX - 1].total
    console.log('Info of player at position ' + topX + ' (A-player) = id: ' + this.rankingInformation[topX - 1].id + ', total: ' + this.rankingInformation[topX - 1].total)

    for (const objItem of this.rankingInformation) {
      // Note: pointsAtX + 1 is a workaround for mismatch between the rankingslist of the frontend and this one. It should actually only be pointsAtX instead.
      if (objItem.id === Number(playerId) && objItem.total >= pointsAtX + 1) {
        // console.log('id: ' + objItem.id + ' - ' + objItem.total)
        console.log('Player position in the ranking: ' + this.rankingInformation.findIndex(x => Number(x.id) === Number(playerId)))

        return true
      }
    }

    console.log('Player with id:' + playerId + ' is not in the ' + topX)

    return false
  }

  public forceRerenderCompetitionsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public getCompetitionIdFromSlug () : number {
    return parseInt(this.$route.params.slug.toString())
  }

  // Retrieve all sign-ups for this competition, and then retrieves the the players information.
  public async retieveCompetitionRegistration () : Promise<void> {
    // console.log('Fetching sign-ups for competition with ID: ' + this.getCompetitionIdFromSlug())

    const staevneId = this.getCompetitionIdFromSlug()
    let memberPlayersUserIdParamOptions = ''
    let foreignPlayersIdParamOptions = ''
    const maxLength = 1000 // Maximum two bytes per char.
    let parameterCharCnt = 0
    let indexPosMark = 0
    let stringToAdd = ''
    let posInStringMark: number[] = [0]
    let partialTempMember: SysMember[] = []

    Competitions.CompetitionMemberRegistrationDataService.getAll('', null, `staevner_id=${staevneId.toString()}`)
      .then((response) => {
        this.signUps = response.data

        this.forceRerenderCompetitionsList()
        this.$forceUpdate()
        this.getRegisteredUsers(this.getUsers)
        let arrIndex = 0

        let trackFirstMemberPlayerFound = false
        let trackFirstForeignPlayerFound = false

        for (const objItem of this.signUps) {
          for (let arrIndex2 = 0; arrIndex2 < objItem.medlem_id.length; arrIndex2++) {
            if (objItem.medlem_id[arrIndex2] !== undefined && objItem.medlem_id[arrIndex2].id !== null) {
              // memberPlayersUserIdParamOptions += ((trackFirstMemberPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.medlem_id[arrIndex2].id).toString())
              stringToAdd = ('&id=' + Number(objItem.medlem_id[arrIndex2].id).toString())

              if (parameterCharCnt + stringToAdd.length > maxLength) {
                posInStringMark.push(memberPlayersUserIdParamOptions.length)
                indexPosMark++
                parameterCharCnt = 0
              }

              memberPlayersUserIdParamOptions += stringToAdd
              parameterCharCnt += stringToAdd.length

              trackFirstMemberPlayerFound = true
            }
          }

          for (let arrIndex2 = 0; arrIndex2 < objItem.usp_id.length; arrIndex2++) {
            if (objItem.usp_id[arrIndex2] !== undefined && objItem.usp_id[arrIndex2].id !== null) {
              foreignPlayersIdParamOptions += ((trackFirstForeignPlayerFound === false && arrIndex2 === 0 ? 'id=' : '&id=') + Number(objItem.usp_id[arrIndex2].id).toString())
              trackFirstForeignPlayerFound = true
            }
          }

          arrIndex++
        }

        // console.log(memberPlayersUserIdParamOptions.length > 0 ? memberPlayersUserIdParamOptions : 'No members signed up')
        // console.log(foreignPlayersIdParamOptions.length > 0 ? foreignPlayersIdParamOptions : 'No foreign players signed up')

        // MembersDataService.getAll('', null, memberPlayersUserIdParamOptions)
        //   .then((response) => {
        //     this.tempSignedUpMembers = response.data
        //     console.log('tempSignedUpMembers: ' + this.tempSignedUpMembers.length)

        //     ForeignPlayersDataService.getAll('', null, foreignPlayersIdParamOptions)
        //       .then((response) => {
        //         this.tempSignedUpForeignPlayers = response.data
        //         console.log('tempSignedUpForeignPlayers: ' + JSON.stringify(response.data))
        //       })
        //       .catch((err) => {
        //         this.error = err
        //       })
        //   })
        //   .catch((err) => {
        //     this.error = err
        //   })
      })
      .catch((err) => {
        this.error = err
      })
      .then(async () => {
        if (memberPlayersUserIdParamOptions.length > 0) {
          // MembersDataService.getAll('', null, memberPlayersUserIdParamOptions)
          //   .then((response) => {
          //     this.tempSignedUpMembers = response.data
          //     // console.log(response.data)
          //   })
          //   .catch((err) => {
          //     this.error = err
          //   })

          // console.log('[retieveCompetitionRegistration()] memberPlayersUserIdParamOptions = ' + memberPlayersUserIdParamOptions)

          for (indexPosMark = 0; indexPosMark <= (posInStringMark.length - 1); indexPosMark++) {
            // this.$Notification.success({ title: 'Henter yderligere medlemsoplysninger, vent venligst ... (del ' + (indexPosMark + 1).toString() + ' ud af ' + posInStringMark.length.toString() + ')' })

            try {
              partialTempMember = (await MembersDataService.asyncGetAll('', null, memberPlayersUserIdParamOptions.slice(posInStringMark[indexPosMark], (indexPosMark === (posInStringMark.length - 1) ? memberPlayersUserIdParamOptions.length : (posInStringMark[indexPosMark + 1]))).slice(1))).data
              this.tempSignedUpMembers = [...this.tempSignedUpMembers, ...partialTempMember]
            } catch (err) {
              // this.$Notification.danger({ title: 'Noget gik galt ved indhentningen af medlemsoplysninger ...' })
              console.log(err)
            }
          }

          memberPlayersUserIdParamOptions = ''
          posInStringMark = []
          // this.$Notification.success({ title: '... færdig. Starter forabejdning af de indlæste oplysninger.' })
        }
      })
      .then(() => {
        if (foreignPlayersIdParamOptions.length > 0) {
          ForeignPlayersDataService.getAll('', null, foreignPlayersIdParamOptions)
            .then((response) => {
              this.tempSignedUpForeignPlayers = response.data
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
  }

  public filteredSignUps (seriesId: number) : any {
    return this.signUps.filter((element) => element.staevne_raekker_id.id === seriesId)
  }

  public viewSignUps (rowId: number, rowName: string) : void {
    this.rowName = rowName
    this.rowId = rowId
    this.rowRegistrationDisplayModal = true
  }

  public getClubNameFromMemberId (memberId: number | null) : string {
    let retVal = ''
    const indexMember = this.tempSignedUpMembers.findIndex(x => x.id === memberId)

    if (indexMember !== undefined && this.tempSignedUpMembers[indexMember] !== undefined) {
      retVal = this.tempSignedUpMembers[indexMember].klubber_id.klubber_klubnavn
    }

    return retVal
  }

  public getFullNameFromMemberId (memberId: number | null) : string {
    let retVal = ''
    const indexMember = this.tempSignedUpMembers.findIndex(x => x.id === memberId)

    if (indexMember !== undefined && this.tempSignedUpMembers[indexMember] !== undefined) {
      retVal = this.tempSignedUpMembers[indexMember].user_id.firstname + ' ' + this.tempSignedUpMembers[indexMember].user_id.lastname
    }

    return retVal
  }

  public getNamesFromObject (objArray: { id: number | null, medlem_licens: number }[], objArray2: { id: number | null, ud_spiller_fornavn: string, ud_spiller_efternavn: string }[] = []) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = retVal + this.getFullNameFromMemberId(objItem.id) + ' [' + (objItem.medlem_licens === null ? '-' : objItem.medlem_licens) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? ']' : '] / ')
    }

    let firtLoop = true

    for (const objItem of objArray2) {
      retVal = retVal + (firtLoop && retVal.length > 0 ? ' / ' : ' ') + (objItem.ud_spiller_fornavn === null ? '-' : objItem.ud_spiller_fornavn + ' ' + objItem.ud_spiller_efternavn) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? '' : ' / ')
      firtLoop = false
    }

    return retVal
  }

  public singleClubOrCountryFromObject (objArray: { id: number | null, klubber_id: number | null }[]) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = (objItem.klubber_id === null ? 'Ingen klub' : this.getClubNameFromMemberId(objItem.id))
    }

    return retVal
  }

  public getClubOrCountryFromObject (objArray: { id: number | null, klubber_id: number | null }[], objArray2: { id: number | null, ud_spiller_land: string }[] = []) : string {
    let retVal = ''

    for (const objItem of objArray) {
      retVal = retVal + (objItem.klubber_id === null ? 'Ingen klub' : this.getClubNameFromMemberId(objItem.id)) + (Number(objItem.id) === Number(objArray[objArray.length - 1].id) ? '' : ' / ')
    }

    let firstLoop = true

    for (const objItem of objArray2) {
      retVal = retVal + (firstLoop && retVal.length > 0 ? ' / ' : '') + (objItem.ud_spiller_land === null ? '-' : objItem.ud_spiller_land) + (Number(objItem.id) === Number(objArray2[objArray2.length - 1].id) ? '' : ' / ')
      firstLoop = false
    }

    return retVal
  }

  public retrieveRegistrationFeesPaid () : void {
    const staevneId = this.getCompetitionIdFromSlug()

    RegistrationFeeDataService.getAll('', null, 'registration_type=1&staevner_id=' + staevneId.toString())
      .then((response) => {
        this.tempRegistrationFeesPaid = response.data
        // console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveResults () : void {
    let tempDateParameter = Date.now()

    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        tempDateParameter = ((response.data === undefined || response.data === null || response.status === 0 ? '' : response.data).trim().length > 9 ? Date.parse((response.data).trim()) : tempDateParameter)

        Competitions.CompetitionResultsDataService.getAll('', null, `staevne_resultater_dato_gte=${this.dateLastYear(tempDateParameter)}&staevne_ranglistepoint_ne=0`)
          .then((response) => {
            this.results = response.data
            // console.log('All Results for the last year: ' + JSON.stringify(this.results))
            if ((new Date(tempDateParameter)).getFullYear() === 2023) {
              // Competitions.OldCompetitionResultsDataService.getAll('', null, `dato_gte=${this.dateLastYear(tempDateParameter)}&Point_ne=0`)
              Competitions.OldCompetitionResultsDataService.getAll('', null, `Dato_gte=${this.dateLastYear(tempDateParameter)}&Point_ne=0`)
                .then((response) => {
                  this.oldResults = response.data
                })
                .catch((err) => {
                  this.error = err
                })
            }
          })
          .catch((err) => {
            this.error = err
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public async getDCTournametRegisteredPlayers (dcTournamentId: any) : Promise<void> {
    await DartConnect.DartConnectDataService.getPlayersOfTournament(dcTournamentId)
      .then((response) => {
        this.dcTournamentPlayers = response.data
      })
      .catch((err: any) => {
        this.error = err
      })
  }

  get tournametRegisteredPlayers () {
    const players: any = []
    if (this.signUps.length > 0) {
      for (const item of this.signUps) {
        if (item.medlem_id.length > 0) {
          for (const medlem of item.medlem_id) {
            players.push({
              licens: (medlem.medlem_licens === null ? '' : medlem.medlem_licens.toString()),
              emailAddress: this.getUserEmail(medlem.user_id),
              firstName: this.getUserFirstName(medlem.user_id),
              lastName: this.getUserLastName(medlem.user_id),
              staevne_raekker_id: item.staevne_raekker_id
            })
          }
        }
        if (item.usp_id.length > 0) {
          for (const foreign of item.usp_id) {
            players.push({
              licens: '',
              emailAddress: foreign.ud_spiller_mail,
              firstName: foreign.ud_spiller_fornavn,
              lastName: foreign.ud_spiller_efternavn,
              staevne_raekker_id: item.staevne_raekker_id
            })
          }
        }
      }
      return players
    } else {
      return players
    }
  }

  public getRegisteredUsers (callback :any) {
    let membersUserIdParamOptions = ''
    const membersData: any[] = [...this.signUps]
    for (const objItem of membersData) {
      if (objItem.medlem_id.length > 0) {
        for (const medlem of objItem.medlem_id) {
          membersUserIdParamOptions += '&id=' + Number(medlem.user_id).toString()
        }
      }
    }
    callback(membersUserIdParamOptions)
  }

  public getUsers (idsParam: string) {
    UsersDataService.getAll('', null, idsParam)
      .then((response: any) => {
        this.usersData = response.data
      })
      .catch((err: any) => {
        this.error = err
      })
  }

  public getUserEmail (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.usersData.findIndex((x: any) => x.id === userId)

    if (indexUsers !== undefined && this.usersData[indexUsers] !== undefined) {
      retVal = this.usersData[indexUsers].email
    }

    return retVal
  }

  public getUserFirstName (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.usersData.findIndex((x: any) => x.id === userId)

    if (indexUsers !== undefined && this.usersData[indexUsers] !== undefined) {
      retVal = this.usersData[indexUsers].firstname
    }

    return retVal
  }

  public getUserLastName (userId: number | null) : string {
    let retVal = '????'
    const indexUsers = this.usersData.findIndex((x: any) => x.id === userId)

    if (indexUsers !== undefined && this.usersData[indexUsers] !== undefined) {
      retVal = this.usersData[indexUsers].lastname
    }

    return retVal
  }

  public findDCCompetitionPlayer () : void {
    if (this.playearSearchInputValue) {
      const lowerCaseSearchKey = this.playearSearchInputValue.toLowerCase()

      this.filteredTournametRegisteredPlayers = this.tournametRegisteredPlayers.filter((item: any) => {
        const { licens, emailAddress, firstName, lastName } = item
        return licens.toLowerCase().includes(lowerCaseSearchKey) ||
          emailAddress.toLowerCase().includes(lowerCaseSearchKey) ||
          firstName.toLowerCase().includes(lowerCaseSearchKey) ||
          lastName.toLowerCase().includes(lowerCaseSearchKey)
      })
      if (this.filteredTournametRegisteredPlayers.length === 0) {
        this.$Message.warning({ text: 'Player not found' })
      }
    } else {
      this.filteredTournametRegisteredPlayers = []
    }
  }

  public async registerPlayerToDCEvent (player: any) : Promise<void> {
    // Get DC tournament player.
    const dcTournamentPlayer = this.dcTournamentPlayers && this.dcTournamentPlayers.find(obj => obj.email === player.emailAddress)
    const dcEventsRegistrationPlayer = { player_id: dcTournamentPlayer?.id }

    // Get DC event data.
    const playersEventId = player.staevne_raekker_id.id
    const dcEvent = (this as any).dcTournamentEvents[playersEventId]

    if (dcTournamentPlayer?.id && dcEvent?.id) {
      await DartConnect.DartConnectDataService.setPlayerToTournamentEvent(dcEvent.id, dcEventsRegistrationPlayer)
        .then((response) => {
          if (response?.roster_id) {
            this.$Message.success({ text: 'Register successful' })
          } else {
            this.$Message.warning({ text: 'Already in event' })
          }
        })
        .catch((error) => {
          console.log('REGISTRATION_ERROR', error)
          this.$Message.danger({ text: 'Something went wrong' })
        })
    } else {
      this.$Message.danger({ text: 'Please try later' })
    }
  }

  // IsDissabled for DC Event registration.
  public isEventRegistrationDisabled (player: any) : boolean {
    // Get DC event data
    const playersEventId = player.staevne_raekker_id.id
    // const dcEvent = (this as any).dcTournamentEvents?.playersEventId
    const dcEvent = (this as any).dcTournamentEvents[playersEventId]

    const currentDateTime = new Date()
    const eventStart = new Date(dcEvent?.start_date)
    // Before 4 hours.
    const openingDateTime = new Date(eventStart.getTime() - 4 * 60 * 60 * 1000)
    // Before 45 minutes.
    const closingDateTime = new Date(eventStart.getTime() - 45 * 60 * 1000)

    if (currentDateTime > openingDateTime && currentDateTime <= closingDateTime) {
      return false
    } else {
      return true
    }
  }

  async mounted () : Promise<void> {
    this.playerDataRetrievedAndReady = false
    this.retrieveRegistrationFeesPaid()
    this.retrieveCompetition()
    this.retieveCompetitionRegistration()
    this.retrieveResults()
  }
}
